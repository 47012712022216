<template>
  <div class="px-10">
    <v-toolbar dense flat class="my-5">
      <div class="text-h4 primary--text">แพ็คเกจของคุณ</div>
    </v-toolbar>
    <v-card elevation="0">
      <v-card-text class="popup-background text-xl-body-1">
        <v-row justify="center">
          <v-col cols="8">
            <v-row>
              <v-col cols="3" offset="2">แพ็กเกจที่คุณใช้งานปัจจุบัน คือ</v-col>
              <v-col cols="3" class="primary--text fz-6">{{
                data.package.name
              }}</v-col>
              <v-col cols="3" offset="2">ใช้ได้สูงสุด</v-col>
              <v-col cols="3">{{
                data.package.is_unlimit
                  ? 'ไม่จำกัด'
                  : `${data.package.bills} บิล`
              }}</v-col>
              <v-col cols="3" offset="2">จะหมดอายุในวันที่</v-col>
              <v-col cols="3">{{
                data.package.end_date | moment($formatDate)
              }}</v-col>
              <v-col cols="3" offset="2">ปริมาณการใช้คงเหลือ</v-col>
              <v-col cols="3">{{
                data.package.is_unlimit
                  ? 'ไม่จำกัด'
                  : `${data.package.bills - data.bill_usage_count} บิล`
              }}</v-col>

              <v-col cols="3" offset="2">ชื่อ ที่อยู่สำหรับออกใบเสร็จ</v-col>
              <v-col cols="3">
                <v-textarea
                  outlined1
                  disabled
                  no-resize
                  v-model="receipt_placeholder"
                  :placeholder="receipt_placeholder"
                />
              </v-col>
              <v-col cols="3">
                <v-btn small outlined color="primary" @click="edit_client_data"
                  >แก้ไข</v-btn
                >
              </v-col>
              <!-- <v-col cols="12">
                <v-progress-linear value="70" height="10" color="light-blue"></v-progress-linear>
                <div class="d-flex">
                  <div class="text-center" :style="{'width':'70%'}">100</div>
                  <div class="text-center" :style="{'width':'30%'}">200</div>
                </div>
              </v-col>
               <v-col cols="6" class="d-flex">
                <v-text-field name="name" label="โค๊ดโปรโมชั่น" outlined dense class="mr-5"></v-text-field>
                <v-btn color="primary" small class="mt-1" elevation="0">ใช้โค๊ด</v-btn>
              </v-col> 
              <v-col cols="4" offset="6">
                <div class="d-flex">
                  <v-checkbox label="ต่ออายุอัตโนมัติ" class="mr-5 mt-0" hide-details></v-checkbox>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" small v-bind="attrs" v-on="on">mdi-information</v-icon>
                    </template>
                    <v-sheet class="pa-3">info</v-sheet>
                  </v-menu>
                </div>
              </v-col> -->
            </v-row>
          </v-col>
          <v-col cols="12"></v-col>
          <v-col cols="12" md="9" lg="7">
            <v-row justify="center">
              <v-col
                cols="12"
                sm="8"
                md="8"
                lg="6"
                v-for="(item, index) in list.product"
                :key="index"
              >
                <PackageCard
                  v-if="
                    loading_product &&
                      (data.package.product_type <= item.product_type ||
                        current_date > data.package.end_date)
                  "
                  :item="item"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog persistent scrollable max-width="1200" v-model="dialogUpdate">
      <ClientDetail
        @onClose="clientDetailClose"
        :name_for_receipt="data.name_for_receipt"
        :taxpayer_number="data.taxpayer_number"
        :company_branch="data.company_branch"
        :address="data.address"
      />
    </v-dialog>
  </div>
</template>

<script>
import PackageCard from '@/components/PackageCard';
import ClientDetail from '@/views/pages/package/your/ClientDetail.vue';

import * as moment from 'moment';
export default {
  components: {
    PackageCard,
    ClientDetail
  },

  data: () => ({
    current_date: moment().format('YYYY-MM-DDTHH:mm:ss'),

    dialogUpdate: false,
    receipt_placeholder: '',
    data: {
      package: {
        name: '',
        is_unlimit: false,
        bills: 0,
        end_date: ''
      }
    },

    list: {
      product: []
    },
    loading_product: false
  }),

  created() {
    this.getReceiptData();
    this.getProductData();
  },
  methods: {
    getProductId(product_id) {
      this.list.product.forEach((item, i) => {
        if (item.product_id == product_id) {
          console.log(item.product_id);
          return item;
        }
      });
    },
    clientDetailClose() {
      this.dialogUpdate = false;
      this.getReceiptData();
    },
    edit_client_data() {
      this.dialogUpdate = true;
    },
    receiptPlaceholder() {
      this.receipt_placeholder =
        this.data.name_for_receipt +
        ' ' +
        this.data.taxpayer_number +
        ' ' +
        this.data.address.address +
        ' ' +
        this.data.address.sub_district +
        ' ' +
        this.data.address.district +
        ' ' +
        this.data.address.province +
        ' ' +
        this.data.address.postal_code +
        ' ' +
        this.data.company_branch;
    },
    async getReceiptData() {
      await this.$axios
        .post(`${this.$baseUrl}/package/get_receipt_data`)
        .then(res => {
          if (typeof res.result.address.address == 'object') {
            res.result.address.address = '';
          }
          this.data = res.result;
          console.log(res.result);
        })
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.receiptPlaceholder();
    },
    async getProductData() {
      this.loading_product = false;
      await this.$axios
        .post(`${this.$baseUrl}/package/get_main_product`)
        .then(res => {
          this.list.product = res.result;
          console.log(this.list.product);
        })
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading_product = true;
    }
  }
};
</script>
